import { rpc } from '../../../backend/Rpc';

export const CHRISTMAS_INFO_URL = 'https://cryptobrowser.site/promo/festive-season-24?utm_source=cta&utm_medium=bnrfes';
export const CHRISTMAS_END_DATE = '2025-01-29T00:00:00.000Z';

export const CHRISTMAS_BANNER_ID = 'ChristmasBannerClosedAt';

export const getIsChristmas = (): boolean => {
  const isChristmasClosedAt = localStorage.getItem(CHRISTMAS_BANNER_ID);

  if (isChristmasClosedAt) return false;

  const currentDate = new Date();
  const christmasDate = new Date(CHRISTMAS_END_DATE);
  const isChristmas = currentDate < christmasDate;

  if (!isChristmas) {
    console.warn('Christmas is over, please remove the Christmas banner', 'color: red; font-size: 20px;');
  }

  return isChristmas;
};

export const fetchSnowflakesCollected = async (): Promise<number | null> => {
  try {
    const response = await rpc.transmit('contests.xmas_24.participant.get', {});
    return response?.state.snowflake_coins ?? null;
  } catch {
    return null;
  }
};
