import React, { useCallback, useEffect, useState } from 'react';
import { WithdrawalDetailsView } from './view/WithdrawalDetailsView';
import { RouteComponentProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '@shared/Loader';
import { HelmetHead } from '../headersComponents/HelmetHead';
import { useTranslation } from 'react-i18next';
import { WithdrawalCancelModal } from './view/WithdrawalCancelModal';
import { useHistory } from 'react-router-dom';
import { BROWSER_BALANCE } from '@utils/links';
import { GET_WITHDRAWALS_REQUEST } from '@service/constant';
import { getWithdrawal, resetWithdrawal } from '@service/actions/getWithdrawal';
import { WithdrawalState } from '@service/reducers/withdrawalReducer';
import { rpc } from '../../backend/Rpc';
import { AllSubheader } from '@pages/headersComponents/AllSubheader';

export type WithdrawalDetailsProps = RouteComponentProps<{ id: string; isBitcoin: string }>;

export const WithdrawalDetails = (props: WithdrawalDetailsProps) => {
  const history = useHistory();
  const { id, isBitcoin } = props.match.params;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const withdrawalState = useSelector((state: { withdrawal: WithdrawalState }) => state.withdrawal);
  const [isModalVisible, setModalVisible] = useState(false);

  const handleWithdrawalConfirm = useCallback(
    async (id: string) => {
      try {
        try {
          await rpc.transmit('withdrawals.reconfirm_withdrawal', { id });
        } catch (e) {}

        history.replace(BROWSER_BALANCE);
        dispatch({ type: GET_WITHDRAWALS_REQUEST });
      } catch (e) {}
    },
    [history, dispatch]
  );

  const handleWithdrawalCancel = useCallback(async () => {
    try {
      if (isBitcoin === 'true') {
        await rpc.transmit('withdrawals.delete_withdrawal', {
          id: withdrawalState.withdrawal!.id,
        });
      } else {
        await rpc.transmit('wallets.withdrawals.cancel', {
          withdrawal_id: withdrawalState.withdrawal!.id,
        });
      }
    } catch (e) {}

    history.replace(BROWSER_BALANCE);
    dispatch({ type: GET_WITHDRAWALS_REQUEST });
  }, [withdrawalState.withdrawal, history, dispatch]);

  useEffect(() => {
    dispatch(getWithdrawal(id, isBitcoin === 'true'));

    return () => {
      dispatch(resetWithdrawal());
    };
  }, [dispatch, id, isBitcoin]);

  if (!withdrawalState.withdrawal || !withdrawalState.hasRequested || withdrawalState.isLoading) {
    return <Loader />;
  }

  return (
    <>
      <HelmetHead title={t('Payment History')} />

      <AllSubheader />
      <WithdrawalDetailsView
        withdrawal={withdrawalState.withdrawal}
        onWithdrawalConfirm={handleWithdrawalConfirm}
        onWithdrawalCancel={() => setModalVisible(true)}
        isBitcoin={isBitcoin === 'true'}
      />
      <WithdrawalCancelModal
        withdrawal={withdrawalState.withdrawal}
        isVisible={isModalVisible}
        onClose={() => setModalVisible(false)}
        onCancel={handleWithdrawalCancel}
        isBitcoin={isBitcoin === 'true'}
      />
    </>
  );
};
