import { ReactNode, useCallback, useRef, useState } from 'react';
import useClickOutside from '@hooks/useClickOutside';
import styles from './CardSwitcher.module.scss';
import cls from 'classnames';
import { ReactComponent as ArrowDownSvg } from '@assets/colorless/arrow-list.svg';
export type CardSwitcherProps = {
  title?: string;
  activeElement: string;
  Icon?: ReactNode;
  List?: ReactNode;
  listClass?: string;
  containerClass?: string;
};

export default function CardSwitcher({
  title,
  activeElement,
  listClass,
  containerClass,
  Icon,
  List,
}: CardSwitcherProps) {
  const [show, setShow] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const handleSelect = useCallback(() => setShow(false), []);
  useClickOutside(ref, handleSelect, show);
  const toggleShow = () => setShow(!show);
  return (
    <div className={cls(styles.container, containerClass)} onClick={() => setShow(!show)} ref={ref}>
      <div className={styles.title}>
        {Icon} {title && <span>{title}</span>}
      </div>
      <div className={styles.select}>
        <span onClick={toggleShow}>{activeElement}</span>
        <ArrowDownSvg onClick={toggleShow} />
      </div>

      {show && <div className={cls([styles.select__list, listClass])}>{List}</div>}
    </div>
  );
}
