import { Trans, useTranslation } from 'react-i18next';
import { ComponentType, useEffect, useMemo, useRef, useState } from 'react';
import {
  CHRISTMAS_BANNER_ID,
  CHRISTMAS_INFO_URL,
  fetchSnowflakesCollected,
  getIsChristmas,
} from '@shared/BannerVariants/Christmas/Christmas.helpers';
import cn from 'classnames';
import styles from './Christmas.module.scss';
import { ReactComponent as CloseSVG } from '@assets/colorless/cross.svg';
import { Button, ButtonVariant } from '@shared/Button/Button';
import { ArrowRightSvg } from '@assets/colorless';
import { useSelector } from 'react-redux';
import {
  christmasDesktopBackground,
  christmasMobileBackground,
  FlakeIcon,
  StatsIcon,
} from '@shared/BannerVariants/Christmas/assets';
import { Root } from '@service/reducers';
import { useContainerWidth } from '@shared/BannerVariants/hooks';
import isNil from 'lodash/isNil';

type ChristmasWrapperProps = {
  onCloseBanner: () => void;
  offsetBottom?: number;
  offsetTop?: number;
  snowflakesCollected: number | null;
};

const ChristmasBannerContent = ({
  onCloseBanner,
  offsetBottom,
  offsetTop,
  snowflakesCollected,
}: ChristmasWrapperProps) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const containerWidth = useContainerWidth(containerRef);
  const userTags = useSelector((state: Root) => state.user.tags);
  const isChristmasParticipant = userTags?.includes('xmas_24');
  const isCompact = (containerWidth ?? 0) <= 768;

  const onClickCloseBanner = () => {
    const timestamp = Date.now();
    localStorage.setItem(CHRISTMAS_BANNER_ID, timestamp.toString());
    onCloseBanner();
  };

  const content = useMemo(() => {
    return !isNil(snowflakesCollected) ? (
      <div className={styles.candiesCollectedContainer}>
        <FlakeIcon />
        <h3 className={styles.candiesCollectedTitle}>{t('Fest24.Snippet.Btn_1')}</h3>
        <div className={styles.candiesCollectedLabel}>{snowflakesCollected}</div>
      </div>
    ) : (
      <p className={styles.description}>
        <Trans i18nKey={'Fest24.Quests.Descr'} components={{ b: <b /> }} />
      </p>
    );
  }, [snowflakesCollected, t]);

  return (
    <>
      <div
        ref={containerRef}
        className={cn(styles.banner, isCompact && styles.responsiveBanner)}
        style={{ marginBottom: offsetBottom, marginTop: offsetTop }}
      >
        <button className={styles.close} onClick={onClickCloseBanner}>
          <CloseSVG color={'#B3B3B3'} />
        </button>
        <div className={styles.background}>
          <img src={isCompact ? christmasMobileBackground : christmasDesktopBackground} alt="banner" />
        </div>
        <div className={styles.content}>
          <div className={styles.texts}>
            <h3 className={styles.title}>{t('Fest24.Quests.Title')}</h3>
            {content}
          </div>
          <div className={styles.buttons}>
            <Button
              type="link"
              target="_blank"
              external
              to={CHRISTMAS_INFO_URL}
              variant={ButtonVariant.Primary}
              Icon={isChristmasParticipant ? <StatsIcon width={20} /> : <ArrowRightSvg />}
              iconPosition="right"
              size="medium"
              className={styles.button}
            >
              {isChristmasParticipant ? t('BF24.Banner.Btn') : t('BF24.Banner.Short.Btn')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export const withChristmasWrapper = <
  P extends {
    offsetBottom?: number;
    offsetTop?: number;
  }
>(
  WrappedComponent: ComponentType<P>
) => {
  const ChristmasHOC: React.FC<P> = (props) => {
    const [snowflakesCollected, setSnowflakesCollected] = useState<undefined | null | number>(undefined);
    const [isChristmasBannerVisible, setIsChristmasBannerVisible] = useState<boolean>(getIsChristmas);

    const isLoaded = snowflakesCollected !== undefined;

    useEffect(() => {
      fetchSnowflakesCollected().then(setSnowflakesCollected);
    }, []);

    if (!isLoaded) return null;
    if (!isChristmasBannerVisible) return <WrappedComponent {...props} />;

    return (
      <ChristmasBannerContent
        onCloseBanner={() => setIsChristmasBannerVisible(false)}
        offsetBottom={props.offsetBottom}
        offsetTop={props.offsetTop}
        snowflakesCollected={snowflakesCollected}
      />
    );
  };

  return ChristmasHOC;
};
