import { useCallback } from 'react';
import { FARM_DASH, FARM_PROMO } from '@utils/links';
import { AffiliateBenefits, AffiliateIntroduction, FarmAboutCode, Container, NftIntroduction } from '@shared/index';
import { AffiliatePromoAccess } from '@shared/AffiliatePromoAccess/AffiliatePromoAccess';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AffilateVideo } from '@shared/AffilateVideo/AffilateVideo';
import { useAnimation } from '@hooks/useAnimation';
import { FarmTabs } from '@shared/FarmTabs/FarmTabs';
import styles from './FarmAbout.module.scss';
import { FarmAboutAccess } from '@pages/FarmAbout/view/components/FarmAboutAccess';
import { StickyFarmTabs } from '@shared/StickyFarmTabs/StickyFarmTabs';
import { farmLinks } from '@shared/FarmPromoCodes/FarmPromoCodes';
import { FarmPromoTabs } from '@shared/FarmPromoCodes/FarmPromoCodesTabs';

export const FarmAboutView = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const onGeneratePress = useCallback(() => {
    history.replace({ pathname: FARM_DASH });
  }, [history]);

  useAnimation();

  return (
    <Container vertical={20}>
      <FarmTabs />
      <AffiliateIntroduction
        MainBlock={
          <AffilateVideo
            player="vimeo"
            previewSrc={'https://cdn.cryptobrowser.site/images/affiliate-redesign/ct-farm/ctfarm-video-preview-upd.png'}
            previewRetinaSrc={
              'https://cdn.cryptobrowser.site/images/affiliate-redesign/ct-farm/ctfarm-video-preview-upd@2x.png'
            }
            decorationImage={'https://cdn.cryptobrowser.site/images/affiliate-redesign/ct-farm/farm-video-coins.png'}
            rootClassname={styles.previewImg}
            videos={{
              en: '734664649',
              ru: '734718051',
              es: '734732865',
              pt: '734737932',
              fr: '734708135',
              de: '734704278',
              it: '734711724',
            }}
          />
        }
        Title={t('Farm_About_First_Block.Title')}
        Description={
          <Trans
            i18nKey="Farm_About_First_Block.Description"
            components={{
              b: <b />,
            }}
          />
        }
        withParallax
        dashboardUrl={FARM_DASH}
        extraButtonUrl={farmLinks[FarmPromoTabs.Usually]}
      />

      <FarmAboutCode
        onCodeGeneratePress={onGeneratePress}
        stepsCss={styles.steps}
        Title={t('Generate a discount code for CryptoTab Farm')}
        isNeedRequest={false}
        Description={
          <Trans
            i18nKey="Generate and use a special code to invite friends and followers to join %(link)sCryptoTab Farm%(link_end)s. By using it, they receive <b>up to 15%% off</b> all in-app purchases, and you — a <b>bonus equal to 15%%</b> of their farm income! Use your referral’s code to also get the discount: <b>come to success working together</b>!"
            components={{
              a: <a href="https://cryptotab.farm/" target="_blank" rel="noreferrer" />,
              b: <b />,
            }}
          />
        }
        imageDesktop={'https://cdn.cryptobrowser.site/images/affiliate-redesign/ct-farm/ctfarm-generate.png'}
        imageDesktop2x={'https://cdn.cryptobrowser.site/images/affiliate-redesign/ct-farm/ctfarm-generate@2x.png'}
        imageMob={'https://cdn.cryptobrowser.site/images/affiliate-redesign/ct-farm/ctfarm-generate-mob.png'}
        imageMob2x={'https://cdn.cryptobrowser.site/images/affiliate-redesign/ct-farm/ctfarm-generate-mob@2x.png'}
      />
      <AffiliateBenefits>
        <AffiliateBenefits.Item title={t('Farm_Affilate_Features_Blok_1')} />
        <AffiliateBenefits.Item title={t('Farm_Affilate_Features_Blok_2')} />
        <AffiliateBenefits.Item title={t('Farm_Affilate_Features_Blok_3')} />
        <AffiliateBenefits.Item title={t('Farm_Affilate_Features_Blok_4')} />
        <AffiliateBenefits.Item title={t('Fast withdrawals with no limits')} />
        <AffiliateBenefits.Item title={t('Farm_Affilate_Features_Blok_6')} />
      </AffiliateBenefits>
      <AffiliatePromoAccess
        promoUrl={FARM_PROMO}
        src={'https://cdn.cryptobrowser.site/images/affiliate-redesign/ct-farm/ctfarm-about-promo@2x.png'}
        srcMob={'https://cdn.cryptobrowser.site/images/affiliate-redesign/ct-farm/ctfarm-about-promo-mob@2x.png'}
        noMore
      />
      <FarmAboutAccess />
      <NftIntroduction />
      <StickyFarmTabs />
    </Container>
  );
};
