import { Trans, useTranslation } from 'react-i18next';
import Container from '../Container/Container';
import styles from './Top.module.scss';
import { ReactComponent as Affiliate } from '@assets/colored/icon-earn-btc.svg';
import { AffiliateFeatures } from '@shared/AffiliateIntroduction/AffiliateFeatures';
import { ReactComponent as CT } from '@assets/browser-icon.svg';
import { Card } from '@shared/Card/Card';
import {
  AllProductTabs,
  CommonProduct,
  CommonProductLinks,
  CommonProductState,
} from '@shared/CommonProduct/CommonProduct';
import { Tabs } from '@shared/Tabs/Tabs';
import { useEffect, useRef, useState } from 'react';
import { BROWSER_DASH, FARM_DASH, POOL_DASH } from '@utils/links';
import { FarmSmLogoSvg } from '@assets/index';
import { CtpLogoSvg, FarmProLogoSvg } from '@assets/colored';
import { Autoplay, Keyboard, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperClass, SwiperSlide, SwiperRef, useSwiper } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import cls from 'classnames';

type CommonAboutTopProps = CommonProductLinks & { isAuth: boolean; isMobile: boolean };

export default function Top(props: CommonAboutTopProps) {
  const { farmLinks, farmProLinks, browserLinks, farmPromos, browserPromos, poolPromos, poolLinks, isAuth, isMobile } =
    props;
  const { t } = useTranslation();
  const [tab, setTab] = useState(AllProductTabs.Link);
  const [state, setState] = useState<CommonProductState>({});
  const baseProps = { state, active: tab, setState };

  if (!isMobile)
    return (
      <Card className={styles.card}>
        <Container
          innerTop={0}
          withParallax
          parallaxClassName={styles.parallax}
          Icon={<Affiliate className="animate__animated" data-anim="fadeInDown" />}
          title={
            <span className="animate__animated" data-anim="fadeIn">
              <Trans
                i18nKey="ct-affiliate_about_text-edits.first.Invite_new_users_and"
                components={{
                  br: <br />,
                }}
              />
            </span>
          }
          text={
            <span className="animate__animated" data-anim="fadeIn">
              <Trans
                i18nKey="ct-affiliate_about_text-edits.first.Invite_new_people_to"
                components={{
                  b: <b />,
                }}
              />
            </span>
          }
        >
          {isAuth && (
            <Tabs
              className={styles.tabs}
              active={tab}
              setState={setTab}
              items={[
                { text: t('Links'), kind: AllProductTabs.Link },
                { text: t('Promo Codes'), kind: AllProductTabs.Promo },
              ]}
            />
          )}
          <div className={styles.products}>
            <CommonProduct
              Icon={<CT />}
              index={'br'}
              title={'CryptoTab Browser'}
              url={BROWSER_DASH}
              text={t('ct-affiliate_about_text-edits.Invite_new_people_to')}
              className="animate__animated anim-card-0"
              data-anim="fadeInRightBig"
              links={browserLinks}
              promoCodes={browserPromos}
              isShowLink={isAuth}
              {...baseProps}
            />
            <CommonProduct
              Icon={<FarmSmLogoSvg width={48} height={48} />}
              title={'CT Farm'}
              className="animate__animated anim-card-1"
              data-anim="fadeInRightBig"
              index={'ctf'}
              url={FARM_DASH}
              text={t('ct-affiliate_about_text-edits.first.Product_Icon.84')}
              links={farmLinks}
              promoCodes={farmPromos}
              isShowLink={isAuth}
              {...baseProps}
            />
            <CommonProduct
              index={'ctfp'}
              className="animate__animated anim-card-2"
              data-anim="fadeInRightBig"
              Icon={<FarmProLogoSvg />}
              title={'CT Farm Pro'}
              url={FARM_DASH}
              text={t('ctfarmpro_gallerycard_desc')}
              links={farmProLinks}
              promoCodes={farmPromos}
              {...baseProps}
              isShowLink={isAuth}
            />
            <CommonProduct
              className="animate__animated anim-card-3"
              data-anim="fadeInRightBig"
              index={'ctp'}
              Icon={<CtpLogoSvg width={48} height={48} />}
              title={'CT Pool'}
              url={POOL_DASH}
              text={t('ct-affiliate_about_text-edits.first.ct_pool_small_card_description')}
              links={poolLinks}
              promoCodes={poolPromos}
              {...baseProps}
              isShowLink={isAuth}
            />
          </div>
          <AffiliateFeatures className={styles.features} isAnimation />
        </Container>
      </Card>
    );
  else
    return (
      <>
        <div className={styles.cardWrapper}>
          <Card className={cls([styles.card, styles.card__mobile])}>
            <Container
              innerTop={0}
              withParallax
              parallaxClassName={styles.parallax}
              Icon={<Affiliate className="animate__animated" data-anim="fadeInDown" />}
              title={
                <span className="animate__animated" data-anim="fadeIn">
                  <Trans
                    i18nKey="ct-affiliate_about_text-edits.first.Invite_new_users_and"
                    components={{
                      br: <br />,
                    }}
                  />
                </span>
              }
              text={
                <span className="animate__animated" data-anim="fadeIn">
                  <Trans
                    i18nKey="ct-affiliate_about_text-edits.first.Invite_new_people_to"
                    components={{
                      b: <b />,
                    }}
                  />
                </span>
              }
            >
              {isAuth && (
                <Tabs
                  className={styles.tabs}
                  active={tab}
                  setState={setTab}
                  items={[
                    { text: t('Links'), kind: AllProductTabs.Link },
                    { text: t('Promo Codes'), kind: AllProductTabs.Promo },
                  ]}
                />
              )}
              <div className={styles.products}>
                <Swiper
                  className={styles.slider}
                  slidesPerView={1}
                  spaceBetween={20}
                  style={isMobile ? {} : { padding: '0 20px' }}
                  modules={[Pagination, Keyboard]}
                  keyboard={{
                    enabled: true,
                    onlyInViewport: false,
                  }}
                  loop={true}
                  pagination={{
                    clickable: true,
                    el: '.swiper-pagination-custom',
                    renderCustom: (_: SwiperClass, current: number, total: number) => {
                      return Array.from({ length: total })
                        .map(
                          (_, index) =>
                            `<span class="${cls('swiper-pagination-bullet', {
                              'swiper-pagination-bullet-active': index + 1 === current,
                            })}"></span>`
                        )
                        .join('');
                    },
                  }}
                >
                  <SwiperSlide>
                    <CommonProduct
                      Icon={<CT />}
                      index={'br'}
                      title={'CryptoTab Browser'}
                      url={BROWSER_DASH}
                      text={t('ct-affiliate_about_text-edits.Invite_new_people_to')}
                      links={browserLinks}
                      promoCodes={browserPromos}
                      isShowLink={isAuth}
                      {...baseProps}
                    />
                  </SwiperSlide>

                  <SwiperSlide>
                    <CommonProduct
                      Icon={<FarmSmLogoSvg width={48} height={48} />}
                      title={'CT Farm'}
                      index={'ctf'}
                      url={FARM_DASH}
                      text={t('ct-affiliate_about_text-edits.first.Product_Icon.84')}
                      links={farmLinks}
                      promoCodes={farmPromos}
                      isShowLink={isAuth}
                      {...baseProps}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <CommonProduct
                      index={'ctfp'}
                      Icon={<FarmProLogoSvg />}
                      title={'CT Farm Pro'}
                      url={FARM_DASH}
                      text={t('ctfarmpro_gallerycard_desc')}
                      links={farmProLinks}
                      promoCodes={farmPromos}
                      {...baseProps}
                      isShowLink={isAuth}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <CommonProduct
                      index={'ctp'}
                      Icon={<CtpLogoSvg width={48} height={48} />}
                      title={'CT Pool'}
                      url={POOL_DASH}
                      text={t('ct-affiliate_about_text-edits.first.ct_pool_small_card_description')}
                      links={poolLinks}
                      promoCodes={poolPromos}
                      {...baseProps}
                      isShowLink={isAuth}
                    />
                  </SwiperSlide>
                </Swiper>
              </div>
            </Container>
          </Card>
          {isMobile && <SliderControls isMobile={isMobile} />}
          <div className={cls(['swiper-pagination', styles.swiper__pagination])}></div>
        </div>
        <Card className={styles.card}>
          <Container innerTop={0} withParallax parallaxClassName={styles.parallax}>
            <AffiliateFeatures className={styles.features} isAnimation />
          </Container>
        </Card>
      </>
    );
}
const SliderControls = ({ isMobile }: { isMobile?: boolean }) => {
  const swiper = useSwiper();

  useEffect(() => {
    swiper?.update();
  }, [swiper]);

  return (
    <div className={styles.sliderControls}>
      {isMobile && <div className={cls(styles.pagination, 'swiper-pagination-custom')}></div>}
    </div>
  );
};
